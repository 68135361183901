import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        
        
        <div className="addbg2">
          <div className="wrapper">
            <header
              style={{
                height: "120px"
              }}
            >
              <div
                style={{
                  height: "67px"
                }}
              >
                <h1 className="logo">
                  <a href="/" />
                </h1>
                <nav>
                  <a href="/site/produkty/" className="link">
                    Produkty
                  </a>
                  <span>| </span>
                  <a href="/site/o-neutrogenie/kim-jestesmy/" className="link">
                    O Neutrogenie
                  </a>
                  <span>| </span>
                  <a href="/site/bad-piekna/" className="link">
                    Bądź piękna
                  </a>
                  <span>| </span>
                  <a href="/site/see-whats-possible/" className="link">
                    See What's Possible
                  </a>
                  <span>| </span>
                  <a href="/site/skora-z-niedoskonalosciami/" className="link">
                    Skóra z niedoskonałościami
                  </a>
                  <span>| </span>
                </nav>
              </div>
              <div
                style={{
                  "padding-left": "185px",
                  height: "53px"
                }}
              >
                <nav
                  className="second"
                  style={{
                    float: "none"
                  }}
                >
                  <a href="/site/hydroboost/" className="link">
                    Hydro Boost
                  </a>
                  <span>| </span>
                  <a href="/site/skin-detox/" className="link">
                    Skin Detox
                  </a>
                  <span>| </span>
                  <a href="/site/cellular-boost/" className="link">
                    Cellular Boost
                  </a>
                  <span />
                </nav>
              </div>
            </header>
            <div className="content">
              <div className="breadcrumb">
                <a href="/">NEUTROGENA®</a>
                <span>&gt;</span>
                <a href="/site/produkty/">Opis</a>
                <span>&gt;</span>
                <a href="/site/product/62/" className="blue">
                  {" "}
                  <p>
                    NEUTROGENA<sup>®</sup>&nbsp; Formuła Norweska Visibly Renew
                    Upiększające serum do ciała
                  </p>{" "}
                </a>
              </div>
              <div className="singleproduct">
                <div className="prodimgcont">
                  <div className="bot">
                    <img
                      src="/site/assets/Uploads/NF-Serum-Flattened-200ml2.jpg"
                      alt="NF Serum Flattened 200ml2"
                    />
                  </div>
                </div>
                <div className="info">
                  <h2>
                    {" "}
                    <p>
                      NEUTROGENA<sup>®</sup>&nbsp; Formuła Norweska Visibly
                      Renew Upiększające serum do ciała
                    </p>
                  </h2>
                  <dl>
                    <dt>Opis:</dt>
                    <dd>
                      <div className="page" title="Page 1">
                        <div className="layoutArea">
                          <div className="column">
                            <p>
                              <span>
                                Skoncentrowane serum upiększające pomaga
                                poprawić elastyczność skóry już w 10 dni,
                                pozostawiając ją widocznie jędrną, gładką i
                                miękką. Stworzone by wyrównywać koloryt skóry i
                                pomagać korygować przebarwienia, jednocześnie
                                poprawiając elastyczność. Zawierające Formułę
                                Norweską serum zostało wzbogacone w minerały
                                stymulujące produkcje kolagenu
                              </span>
                              <span>* </span>
                              <span>
                                oraz ekstrakt z soi. Ma delikatny zapach i
                                lekką, nietłustą konsystencję. Natychmiastowo
                                się wchłania, nawilża przez 24 godziny, nadaje
                                delikatny blask dla pięknej i zdrowo
                                wyglądającej skóry. Odpowiednie dla każdego
                                rodzaju skóry.{" "}
                              </span>
                            </p>
                            <p>
                              <span>*w badaniach in-vitro&nbsp;</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </dd>
                  </dl>
                  <dl>
                    <dt>Stosowanie:</dt>
                    <dd>
                      Stosować codziennie na całe ciało, najlepiej po
                      kąpieli/prysznicu
                    </dd>
                  </dl>
                  <dl>
                    <dt>Opakowanie:</dt>
                    <dd>200ml</dd>
                  </dl>
                  <dl className="last">
                    <dt>Cena:</dt>
                    <dd>
                      20,99zł{" "}
                      <p>
                        {" "}
                        <br />
                        *cena detaliczna sugerowana przez producenta
                      </p>
                    </dd>
                  </dl>
                </div>
                <div className="fb">
                  <div
                    style={{
                      width: "250px",
                      height: "auto",
                      float: "left"
                    }}
                  >
                    <iframe
                      src="//www.facebook.com/plugins/like.php?href=&send=false&layout=button_count&width=200&show_faces=false&font&colorscheme=light&action=like&height=21&locale=pl_PL"
                      scrolling="no"
                      frameBorder="0"
                      style={{
                        border: "none",
                        overflow: "hidden",
                        width: "200px",
                        height: "21px"
                      }}
                      allowTransparency="true"
                    />
                  </div>
                </div>
              </div>
              <div className="products">
                <div className="row">
                  <div className="rowtitle">Zobacz podobne produkty:</div>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/site/product/13/"
                  >
                    <img src="/site/assets/Uploads/38.png" alt="38" />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Formuła Norweska Odżywcza emulsja
                        do ciała z maliną nordycką
                      </p>
                      <p>&nbsp;</p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/site/product/14/"
                  >
                    <img
                      src="/site/assets/Uploads/Packshoty-200-ml/balsam-z-malina-nordycka-200ml-2.png"
                      alt="balsam z malina nordycka 200ml 2"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Formuła Norweska Odżywczy balsam
                        z maliną nordycką
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/site/product/15/"
                  >
                    <img
                      src="/site/assets/Uploads/NTG-3574660143843-Norwegian-Formula-Deep-Moisture-Comfort-Balm-200ml-00000-2.png"
                      alt="NTG 3574660143843 Norwegian Formula Deep Moisture Comfort Balm 200ml 00000 2"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Formuła Norweska Głęboko
                        nawilżający balsam do ciała i twarzy
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/site/product/16/"
                  >
                    <img
                      src="/site/assets/Uploads/Packshoty-200-ml/intensywnie-odzywczy-balsam-200ml-2.png"
                      alt="intensywnie odzywczy balsam 200ml 2"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Formuła Norweska Intensywnie
                        odżywczy balsam
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/site/product/18/"
                  >
                    <img
                      src="/site/assets/Uploads/Packshoty-200-ml/intensywnie-regenerujacy-balsam-200ml-2.png"
                      alt="intensywnie regenerujacy balsam 200ml 2"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Formuła Norweska Intensywnie
                        regenerujący balsam do ciała
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bluebar">
          <div className="bar">
            <div className="wrapper">
              <a href="/site/o-neutrogenie/kim-jestesmy/">
                neutrogena<sup>®</sup> obecnie
              </a>
              <span>|</span>
              <a href="/site/o-neutrogenie/formula-norweska/">
                czym jest formuła norweska?
              </a>
              <span>|</span>
              <a href="/site/o-neutrogenie/historia/">poznaj naszą historię</a>
              <span>|</span>
              <a href="/site/produkty/">zobacz produkty neutrogena</a>
            </div>
          </div>
        </div>
        <footer>
          <div className="wrapper">
            <nav>
              <a href="/site/" className="logo2" />
              <a href="/site/nota-prawna/">Nota Prawna</a>
              <a href="/site/polityka-prywatnosci/">Polityka Prywatności</a>
               <a href="/site/cookies/">Polityka cookies</a>
              <a href="/site/kontakt/">Zachęcamy do kontaktu</a>
                           <span>© JNTL Consumer Health (Poland) sp. z o.o. 2023 </span>
            </nav><a id="ot-sdk-btn" className="ot-sdk-show-settings">Ustawienia plików Cookie</a>
            <p>
              © JNTL Consumer Health (Poland) sp. z o.o., 2023. Ostatnia aktualizacja 01.08.2023<br></br>
Strona zarządzana jest przez JNTL Consumer Health (Poland) sp. z o.o., ul. Iłżecka 24, 02-135 Warszawa. Spółka zarejestrowana w Sądzie Rejonowym dla m. st. Warszawy, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego, pod numerem KRS 0000937574, kapitał zakładowy w wysokości 107.357.450 PLN, NIP 5272980564. JNTL Consumer Health (Poland) sp. z o.o. ponosi wyłączną odpowiedzialność za treści zawarte na niniejszej stronie. Strona jest przeznaczona dla użytkowników z Polski.<br></br>
Niniejsza witryna zawiera łącza do witryn, które nie podlegają naszej polityce prywatności. Zachęcamy do zapoznawania się z politykami prywatności każdej odwiedzanej witryny.
              <br />{" "}
            </p>{" "}
            <p>
              <span>
                Strona została zoptymalizowana pod kątem przeglądarek: Internet
                Explorer 7 i wyższych, Mozilla Firefox, Google Chrome, Opera,
                Safari
              </span>{" "}
              <span className="right">
                Zalecana rozdzielczość: 1024 x 768 pikseli
              </span>{" "}
            </p>
          </div>
        </footer>
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '<iframe src="//www.googletagmanager.com/ns.html?id=GTM-N7SHM8" height="0" width="0" style="display:none;visibility:hidden"></iframe>'
          }}
        />
        
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=227575234312850&amp;ev=PageView&amp;noscript=1">'
          }}
        />
      </div>
    );
  }
}

export default Page;